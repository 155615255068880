// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React from 'react';
import PropTypes from 'prop-types';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material UI Icons
// ───────────────────────────────────────────────────────────────────────────
import Menu from '@mui/icons-material/Menu';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material UI Components
// ───────────────────────────────────────────────────────────────────────────
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

// ───────────────────────────────────────────────────────────────────────────
// Application Components
// ───────────────────────────────────────────────────────────────────────────
import ProfileDropdown from './ProfileDropdown.jsx';
import SearchBar from './SearchBar.jsx';

function TopBar({
  // Props
  onClick,
  onClose,
}) {
  const ultraNarrow = useMediaQuery('(max-width:369px)');

  return (
    <AppBar
      position="fixed"
      sx={(theme) => ({
        marginTop: 0,
        boxShadow: 'none',
        zIndex: theme.zIndex.drawer + 1,
      })}
      data-cy="navbar"
    >
      <Toolbar>
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center', width: '100%' }}>
          <IconButton size="large" edge="start" color="inherit" aria-label="menu" onClick={onClick}>
            <Menu />
          </IconButton>

          <Stack direction="row" sx={{ display: { xs: 'none', sm: 'flex' } }}>
            <img src="/favicon.ico" alt="TZ" height="32px" />
            <Typography variant="h6" noWrap component="div" sx={{ ml: '10px' }}>
              Connect
            </Typography>
          </Stack>

          <Box onClick={onClose} sx={{ flexGrow: 1 }}>
            <SearchBar />
          </Box>

          {!ultraNarrow && <ProfileDropdown />}
        </Stack>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  onClick: PropTypes.func,
  onClose: PropTypes.func,
};

export default TopBar;
