import React from 'react';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid2';

function PageLoading() {
  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <Grid container>
      <Grid sx={{ mt: 10, textAlign: 'center' }} size={12}>
        <CircularProgress size={150} />
      </Grid>
    </Grid>
  );
}

export default PageLoading;
