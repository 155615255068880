// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React from 'react';
import { useForm, useFormState } from 'react-hook-form';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Icons
// ───────────────────────────────────────────────────────────────────────────
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';

// ───────────────────────────────────────────────────────────────────────────
// ─── MUI Components
// ───────────────────────────────────────────────────────────────────────────
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Popover from '@mui/material/Popover';
import { alpha } from '@mui/material/styles';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';

// ───────────────────────────────────────────────────────────────────────────
// ─── TZ Components
// ───────────────────────────────────────────────────────────────────────────
import { splitFilterInput } from '@tzmedical/react-hooks/useFilter';

// ───────────────────────────────────────────────────────────────────────────
// ─── Application Components
// ───────────────────────────────────────────────────────────────────────────
import SearchContext from '../contexts/SearchContext.jsx';
import FormStringInput from './FormStringInput.jsx';

const relativeOptions = [
  { id: '>', name: 'Greater than' },
  { id: '>=', name: 'Greater than or equal to' },
  { id: '=', name: 'Equal to' },
  { id: '<=', name: 'Less than or equal to' },
  { id: '<', name: 'Less than' },
];

const negatableOptions = [
  { id: 'select', name: '-- Select Option --', value: '' },
  { id: '+', name: 'Include' },
  { id: '-', name: 'Exclude' },
];

//---------------------------------------------------------------------------
function SearchBar() {
  const { search, searchHelper, searchDecorator, setSearch } = React.useContext(SearchContext);
  const theme = useTheme();
  const anchorEl = React.useRef();

  const handleSearch = React.useCallback(
    (event) => {
      setSearch(event.target.value);
      window.scrollTo(0, 0);
    },
    [setSearch]
  );

  //---------------------------------------------------------------------------
  // Form Submission
  //---------------------------------------------------------------------------
  const defaultValues = React.useMemo(() => {
    const defaults = {};
    if (Array.isArray(searchHelper)) {
      searchHelper.forEach((helper) => {
        if (helper.variant === 'relative') {
          defaults[`${helper.keyword}-relative`] = '>';
        } else if (helper.variant === 'negatable') {
          defaults[`${helper.keyword}-negatable`] = '+';
        } else if (helper.variant === 'toggle') {
          defaults[`${helper.keyword}-negatable`] = '';
        }
        defaults[helper.keyword] = '';
      });
    }
    return defaults;
  }, [searchHelper]);

  const { handleSubmit, control, reset } = useForm({ defaultValues });
  const { dirtyFields } = useFormState({ control });

  const clearSearch = React.useCallback(() => {
    setSearch('');
    reset(defaultValues);
    window.scrollTo(0, 0);
  }, [defaultValues, reset, setSearch]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = React.useCallback(() => setOpen(true), []);
  const handleClose = React.useCallback(() => setOpen(false), []);

  const onSubmit = React.useCallback(
    async (data) => {
      // Construct the search string based on the searchHelper config
      const newSearch = searchHelper?.reduce((string, row) => {
        // Holds the entire parsed search thing with necessary params
        let searchBuilder = string;
        if (row.variant === 'toggle') {
          if (dirtyFields[`${row.keyword}-negatable`]) {
            const isNegated = data[`${row.keyword}-negatable`] === '-';
            searchBuilder += isNegated ? `-${row.keyword} ` : `${row.keyword} `;
          }
        } else if (dirtyFields[row.keyword]) {
          const wordsOrPhrases = splitFilterInput(data[row.keyword]);
          // wordsOrPhrases returns an array like [{fields: [], value: "input"}]
          searchBuilder += wordsOrPhrases.reduce((substring, phrase) => {
            let newSubstring = substring;
            const value = phrase.value.includes(' ') ? `"${phrase.value}"` : phrase.value;
            if (row.variant === 'global') {
              if (row.keyword === '-') {
                newSubstring += `-${value} `;
              } else {
                newSubstring += `${value} `;
              }
            } else if (row.variant === 'negatable') {
              const isNegated = data[`${row.keyword}-negatable`] === '-';
              if (isNegated) {
                newSubstring += `-${row.keyword}:${value} `;
              } else {
                newSubstring += `${row.keyword}:${value} `;
              }
            } else if (row.variant === 'relative') {
              const relativeString = data[`${row.keyword}-relative`];
              if (relativeString === '=') {
                newSubstring += `${row.keyword}:${value} `;
              } else {
                newSubstring += `${row.keyword}:${relativeString}${value} `;
              }
            } else if (row.variant === 'toggle') {
              const isNegated = data[`${row.keyword}-negatable`] === '-';
              if (isNegated) {
                newSubstring += `-${row.keyword} `;
              } else {
                newSubstring += `${row.keyword} `;
              }
            } else {
              newSubstring += `${value} `;
            }
            return newSubstring;
          }, '');
        }
        return searchBuilder;
      }, '');

      setSearch(newSearch);
      setOpen(false);
      window.scrollTo(0, 0);
    },
    [dirtyFields, searchHelper, setSearch]
  );

  React.useEffect(() => {
    // Clear form on rerender
    return () => reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <Grid container sx={{ alignItems: 'center' }}>
      <Grid size="grow">
        <OutlinedInput
          color="primary"
          fullWidth
          ref={anchorEl}
          sx={{
            color: theme.palette.common.white,
            backgroundColor: alpha(theme.palette.common.white, 0.15),
            my: '5px',
            borderRadius: '10px',
            '&:hover': {
              backgroundColor: alpha(theme.palette.common.white, 0.25),
            },
          }}
          data-cy="employee-search"
          startAdornment={
            <InputAdornment sx={{ color: theme.palette.common.white }} position="start">
              <SearchIcon />
            </InputAdornment>
          }
          placeholder="Search here..."
          value={search}
          onChange={handleSearch}
          endAdornment={
            <InputAdornment sx={{ color: theme.palette.common.white }} position="end">
              <ButtonGroup variant="outlined">
                {search && (
                  <IconButton
                    size="large"
                    aria-label="clear-search"
                    data-cy="clear-search"
                    color="inherit"
                    onClick={clearSearch}
                  >
                    <CancelIcon />
                  </IconButton>
                )}
                {searchHelper && (
                  <IconButton
                    size="large"
                    aria-label="search-helper"
                    data-cy="search-helper"
                    color="inherit"
                    onClick={handleOpen}
                  >
                    <TuneIcon />
                  </IconButton>
                )}
              </ButtonGroup>
              {searchHelper && (
                <Popover
                  id="search-helper"
                  anchorEl={anchorEl.current}
                  open={open}
                  onClose={handleClose}
                  data-cy="advanced-search-popup"
                  keepMounted
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Box sx={{ width: anchorEl?.current?.offsetWidth, m: 3 }}>
                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                      {searchHelper.map((helper) => (
                        <Grid
                          container
                          spacing={2}
                          key={helper.keyword}
                          sx={{ alignItems: 'flex-end' }}
                        >
                          <Grid sx={{ display: { xs: 'none', md: 'block' } }} size={{ md: 3 }}>
                            <Typography>{helper.label}</Typography>
                          </Grid>
                          {helper.variant === 'relative' && (
                            <Grid size={{ xs: 6, sm: 4, md: 3 }}>
                              <FormStringInput
                                control={control}
                                defaultValue={defaultValues[`${helper.keyword}-relative`]}
                                name={`${helper.keyword}-relative`}
                                options={relativeOptions}
                                id={`${helper.keyword}-relative-search-input`}
                              />
                            </Grid>
                          )}
                          {(helper.variant === 'negatable' || helper.variant === 'toggle') && (
                            <Grid size={{ xs: 6, sm: 4, md: 3 }}>
                              <FormStringInput
                                control={control}
                                defaultValue={defaultValues[`${helper.keyword}-negatable`]}
                                label=" "
                                name={`${helper.keyword}-negatable`}
                                options={negatableOptions}
                                id={`${helper.keyword}-negatable-search-input`}
                              />
                            </Grid>
                          )}
                          {helper.variant !== 'toggle' && (
                            <Grid size="grow">
                              <FormStringInput
                                control={control}
                                defaultValue={defaultValues[helper.keyword]}
                                label={helper.label}
                                name={helper.keyword}
                                id={`${helper.keyword}-search-input`}
                              />
                            </Grid>
                          )}
                        </Grid>
                      ))}
                      <Box sx={{ textAlign: 'right', marginTop: 3 }}>
                        <Button color="secondary" onClick={handleClose}>
                          Cancel
                        </Button>
                        <Button
                          color="secondary"
                          data-cy="submit-search"
                          variant="contained"
                          type="submit"
                          sx={{ ml: 2 }}
                        >
                          Search
                        </Button>
                      </Box>
                    </form>
                  </Box>
                </Popover>
              )}
            </InputAdornment>
          }
        />
      </Grid>
      <Grid size="auto">{searchDecorator}</Grid>
    </Grid>
  );
}

export default SearchBar;
