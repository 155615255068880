import React from 'react';
import { useForm } from 'react-hook-form';
import { useAuth0 } from '@auth0/auth0-react';
import { useConfirm } from 'material-ui-confirm';
import PropTypes from 'prop-types';
import isEmail from 'validator/lib/isEmail';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

import LoadingButton from '@mui/lab/LoadingButton';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';

import axios from '../../axiosClient.js';
import Alert from '../common/Alert.jsx';
import FormAutocompleteInput from '../FormAutocompleteInput.jsx';
import FormCheckboxInput from '../FormCheckboxInput.jsx';
import FormDocumentInput from '../FormDocumentInput.jsx';
import FormStringInput from '../FormStringInput.jsx';

function EmployeeForm({ employee, employeeList, setRefreshData }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = React.useCallback(() => setOpen(true), []);
  const handleClose = React.useCallback(() => setOpen(false), []);

  const [error, setError] = React.useState(null);

  const [loading, setLoading] = React.useState(false);

  const [managerOptions, setManagerOptions] = React.useState([]);

  const [departmentOptions, setDepartmentOptions] = React.useState([]);
  const confirm = useConfirm();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const [managersData, departmentsData] = await Promise.all([
          axios({ method: 'GET', url: 'api/managers' }),
          axios({ method: 'GET', url: 'api/departments' }),
        ]);

        setManagerOptions(
          managersData.data.map((manager) => ({
            name: manager.fullName,
            id: manager.id,
          }))
        );

        setDepartmentOptions(
          departmentsData.data.map((department) => ({
            name: department.name,
            id: department.name,
          }))
        );
      } catch (e) {
        setError(e.message);
      }

      setLoading(false);
    };

    if (open) {
      fetchData();
    }
  }, [open]);

  const { control, handleSubmit } = useForm();
  const { user } = useAuth0();

  const checkEmailUniqueness = React.useCallback(
    (email) => {
      // If we are updating, remove ourselves from the list so we aren't checking ourselves
      const uniqueEmail = employeeList.some(
        (element) => element.email === email && element.id !== employee?.id
      );
      if (uniqueEmail && email) {
        return 'An employee with this email already exists';
      }
      return true;
    },
    [employee, employeeList]
  );

  const onSubmit = React.useCallback(
    async ({ image, ...fields }) => {
      setLoading(true);
      try {
        const departmentExists = departmentOptions.some((dep) => dep.name === fields.department);

        if (!departmentExists) {
          try {
            await confirm({
              description: 'Would you like to create a new department for this employee?',
              confirmationButtonProps: {
                'data-cy': 'confirm-dep',
              },
            });
          } catch (err) {
            setLoading(false);
            return;
          }
        }

        if (employee?.id) {
          await axios.put(`/api/user/${employee?.id}`, { ...fields, updatedBy: user?.email });
        } else {
          await axios.post('/api/user', { ...fields, updatedBy: user?.email });
        }

        // if an image was provided upload it
        if (image) {
          // add email and updated by fields
          // for multipart data the image
          // ⚠️ MUST ⚠️ be the LAST item appended to the form data!!!!!!
          const formData = new FormData();
          formData.append('email', fields.email);
          formData.append('updatedBy', user?.email);
          formData.append('file', image);

          await axios.post('/api/image', formData);
        }

        setRefreshData(true);
        setOpen(false);
      } catch (err) {
        setError(
          err.response?.data?.message ||
            err.response?.data?.error ||
            err.message ||
            'Something bad happened...'
        );
      }
      setLoading(false);
    },
    [confirm, departmentOptions, employee?.id, setRefreshData, user?.email]
  );

  return (
    <>
      {employee?.id ? (
        <IconButton
          aria-label="edit"
          color="secondary"
          data-cy={`edit-employee-${employee.id}`}
          onClick={handleOpen}
        >
          <EditIcon />
        </IconButton>
      ) : (
        <Button
          variant="contained"
          color="secondary"
          onClick={handleOpen}
          fullWidth
          data-cy="add-employee"
          startIcon={<AddIcon />}
        >
          Add Employee
        </Button>
      )}
      <Dialog open={open} onClose={handleClose}>
        <Alert message={error} setMessage={setError} level="error" data-cy="error-alert" />
        <DialogTitle>{employee?.id ? 'Edit Employee' : 'Add Employee'}</DialogTitle>
        <DialogContent>
          {!managerOptions.length && (
            <Backdrop
              sx={(theme) => ({
                color: '#fff',
                zIndex: theme.zIndex.drawer + 1,
              })}
              open
            >
              <CircularProgress />
            </Backdrop>
          )}
          <Grid container spacing={2}>
            <Grid
              size={{
                xs: 12,
                sm: 6,
              }}
            >
              <FormStringInput
                control={control}
                defaultValue={employee?.id ? employee.firstName : ''}
                label="First Name"
                name="firstName"
                id="first name"
                required
                rules={{
                  required: 'Please enter a first name',
                }}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
                sm: 6,
              }}
            >
              <FormStringInput
                control={control}
                defaultValue={employee?.id ? employee.lastName : ''}
                label="Last Name"
                name="lastName"
                id="last name"
                required
                rules={{
                  required: 'Please enter a last name',
                }}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
                sm: 6,
              }}
            >
              <FormStringInput
                control={control}
                defaultValue={employee?.id ? employee.email : ''}
                label="Email"
                name="email"
                id="email"
                rules={{
                  validate: {
                    isValidEmail: (email) =>
                      isEmail(email) || email === '' ? true : 'Please enter a valid email address',
                    uniqueEmail: checkEmailUniqueness,
                  },
                }}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
                sm: 6,
              }}
            >
              <FormStringInput
                control={control}
                defaultValue={employee?.id ? employee.phone : ''}
                label="Phone Number"
                name="phone"
                id="phone"
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
                sm: 6,
              }}
            >
              <FormStringInput
                control={control}
                defaultValue={employee?.id ? employee.extension : ''}
                label="Extension"
                name="extension"
                id="extension"
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
                sm: 6,
              }}
            >
              <FormAutocompleteInput
                control={control}
                required
                freeSolo
                defaultValue={employee?.department.name || ''}
                label="Department"
                name="department"
                id="department"
                options={departmentOptions}
                rules={{
                  required: 'Please enter a Department name',
                }}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
                sm: 6,
              }}
            >
              <FormStringInput
                control={control}
                defaultValue={employee?.id ? employee.title : ''}
                label="Title"
                name="title"
                id="title"
                required
                rules={{
                  required: 'Please enter an employee title',
                }}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
                sm: 6,
              }}
            >
              <FormAutocompleteInput
                control={control}
                defaultValue={employee?.manager?.id}
                label="Manager"
                name="managerId"
                id="manager"
                options={managerOptions}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
                sm: 6,
              }}
            >
              <FormCheckboxInput
                control={control}
                defaultValue={employee?.isManager}
                name="isManager"
                label="Is Manager"
                id="is-manager"
              />
            </Grid>
            <Grid size={12}>
              <FormDocumentInput
                control={control}
                name="image"
                label="Drag & Drop an Image"
                id="image-dnd"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" data-testid="cancel-button" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            data-testid="submit-button"
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EmployeeForm;

EmployeeForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  employee: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  employeeList: PropTypes.array,
  setRefreshData: PropTypes.func,
};
