import React from 'react';
import PropTypes from 'prop-types';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Avatar from '@mui/material/Avatar';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import DeleteEmployee from './actions/DeleteEmployee.jsx';
import EmployeeForm from './actions/EmployeeForm.jsx';
import EmployeeDetails from './EmployeeDetails.jsx';
import EmployeePhoneButton from './EmployeePhoneButton.jsx';
import FavoriteEmployee from './FavoriteEmployee.jsx';

const OFFICE_DIRECTORY_DIRECT_CALL_IN_NUMBER = '+19712504238';

function EmployeeListItem({
  // Props
  employee,
  employeeList,
  setRefreshData,
  forceExpand,
  allowEdit,
}) {
  const [expanded, setExpanded] = React.useState(false);
  const mobile = !useMediaQuery('(min-width: 600px)');

  const handleClick = React.useCallback(() => {
    setExpanded((previous) => !previous);
  }, [setExpanded]);

  return (
    <Accordion
      expanded={expanded || forceExpand}
      onChange={handleClick}
      data-cy="employee-item"
      data-testid={employee.email}
      sx={{ height: '100%' }}
    >
      <AccordionSummary>
        <Grid
          container
          spacing={2}
          columns={12}
          sx={{
            justifyContent: 'left',
            alignItems: 'center',
            width: '100%',
          }}
        >
          {!expanded && (
            <Grid sx={{ display: { xs: 'block', sm: 'none' } }} size="auto">
              {/* instead of using the dummy image route, set image to the default avatar icon */}
              <Avatar
                src={employee.fileName && `/api/image/${employee.fileName}`}
                size="lg"
                data-cy={`employee-portrait-${employee.id}`}
                sx={(theme) => ({
                  bgcolor: theme.palette.action.disabledBackground,
                })}
                slotProps={{
                  img: {
                    loading: 'lazy',
                  },
                }}
              />
            </Grid>
          )}
          <Grid size="grow">
            <Typography sx={{ fontWeight: 'bold' }}>{employee.fullName}</Typography>
            <Typography sx={{ fontStyle: 'italic' }}>{employee.department.name}</Typography>
          </Grid>
          <Grid size="auto">
            <ButtonGroup sx={{ alignItems: 'center' }}>
              <FavoriteEmployee id={employee.id} />
              {!!mobile && (
                <EmployeePhoneButton
                  officeNumber={OFFICE_DIRECTORY_DIRECT_CALL_IN_NUMBER}
                  extension={employee.extension}
                  phone={employee.phone}
                />
              )}
              {allowEdit && (
                <>
                  <EmployeeForm
                    setRefreshData={setRefreshData}
                    employee={employee}
                    employeeList={employeeList}
                    data-cy={`edit-employee-${employee.fullName.replace(' ', '-')}`}
                  />
                  <DeleteEmployee employee={employee} setRefreshData={setRefreshData} />
                </>
              )}
            </ButtonGroup>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <EmployeeDetails
          employee={employee}
          directoryCallIn={OFFICE_DIRECTORY_DIRECT_CALL_IN_NUMBER}
        />
      </AccordionDetails>
    </Accordion>
  );
}

export default EmployeeListItem;

EmployeeListItem.propTypes = {
  employee: PropTypes.shape({
    department: PropTypes.shape({ name: PropTypes.string }),
    email: PropTypes.string.isRequired,
    extension: PropTypes.string,
    fileName: PropTypes.string,
    fullName: PropTypes.string,
    phone: PropTypes.string,
    title: PropTypes.string,
    id: PropTypes.string,
  }),
  employeeList: PropTypes.arrayOf(
    PropTypes.shape({
      department: PropTypes.shape({ name: PropTypes.string }),
      email: PropTypes.string.isRequired,
      extension: PropTypes.string,
      fileName: PropTypes.string,
      fullName: PropTypes.string,
      phone: PropTypes.string,
      title: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  setRefreshData: PropTypes.func,
  forceExpand: PropTypes.bool,
  allowEdit: PropTypes.bool,
};
