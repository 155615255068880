/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import UploadDocumentIcon from '@mui/icons-material/UploadFile';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import FormDocumentTheme from './FormDocumentTheme.jsx';

function FormDocumentInput({ control, name, label, id, rules }) {
  const { baseStyle, activeStyle, acceptStyle, rejectStyle } = FormDocumentTheme();
  const nameValidator = React.useCallback(
    (document) => {
      if (rules?.pattern) {
        const pattern = rules.pattern.value || rules.pattern;
        const errorMessage = rules.pattern.message || '';
        if (!pattern.test(document.name)) {
          return {
            code: 'incorrect-suffix',
            message: errorMessage,
          };
        }
      }
      return null;
    },
    [rules?.pattern]
  );

  return (
    <Controller
      name={name}
      control={control}
      shouldUnregister
      rules={{
        required: !!rules?.required?.value,
      }}
      render={({ field: { onChange, value } }) => {
        const onDrop = React.useCallback(
          (acceptedDocuments) => {
            if (acceptedDocuments.length > 0) {
              onChange(acceptedDocuments[0]);
            } else {
              onChange(undefined);
            }
          },
          [onChange]
        );
        const {
          fileRejections,
          getRootProps,
          getInputProps,
          isDragActive,
          isDragAccept,
          isDragReject,
        } = useDropzone({
          maxDocuments: 1,
          validator: nameValidator,
          onDrop,
        });
        const style = React.useMemo(
          () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
          }),
          [isDragActive, isDragReject, isDragAccept]
        );

        return (
          <>
            <div {...getRootProps({ style })}>
              <input {...getInputProps({ onChange })} id={id} />
              <Stack spacing={3} direction="row" sx={{ alignItems: 'center' }}>
                <div>{label}</div>
                <Divider flexItem orientation="vertical" variant="middle" />
                <Button data-cy={`browse-${id}`} variant="contained">
                  Browse
                </Button>
              </Stack>
            </div>
            {value && (
              <Typography sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <UploadDocumentIcon color="secondary" />
                &nbsp;{value.name}
              </Typography>
            )}
            {!value && rules?.required?.value && (
              <Typography sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <ErrorOutlineIcon color="error" />
                &nbsp;{rules.required.message}
              </Typography>
            )}
            {fileRejections.length > 0 && (
              <Typography sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <ErrorOutlineIcon color="error" />
                &nbsp;{fileRejections[0].errors[0].message}
              </Typography>
            )}
          </>
        );
      }}
    />
  );
}

FormDocumentInput.propTypes = {
  /**
   * The [control](https://react-hook-form.com/api/useform/control)
   * object provided by invoking `useForm`.
   * Optional when using `FormProvider`.
   *
   * @example
   * ```js
   * import { useForm } from 'react-hook-form';
   * const { control, handleSubmit, getValues } = useForm();
   * ```
   * pass in control
   */
  control: PropTypes.object.isRequired,

  /**
   * Unique name of your input field
   */
  name: PropTypes.string.isRequired,

  /**
   * The label content
   */
  label: PropTypes.string.isRequired,

  /**
   * The id of your input field
   */
  id: PropTypes.string,

  /**
   * Validation rules in the same format for `register`,
   * which includes:
   * required, min, man, minLength, maxLength, pattern, validate
   *
   * @example
   * ```jsx
   * rules={{ required: true }}
   * ```
   */

  rules: PropTypes.object,
};

export default FormDocumentInput;
